import { bikeSensors } from "./serviceAdditionalData";

export default {
    busStopPublic: "https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Stotel%c4%97s/FeatureServer/0",

    schoolPre: "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Ikimokyklinis_ugdymas/FeatureServer/30",
    schoolElementary: "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__pradinis_ugdymas/FeatureServer/30",
    schoolBasic: "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Vidurinis_ugdymas/FeatureServer/30",
    schoolHigher: "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Aukstasis_mokslas/FeatureServer/30",

    hospitals: "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Ligonines/FeatureServer/28",
    dentist: "https://opencity.vplanas.lt/server/rest/services/Hosted/VDA_2023_m_veikiantys_ukio_subjektai__Med_ir_odon_veikla/FeatureServer/28",

    statePublicOffices: "https://opencity.vplanas.lt/server/rest/services/Hosted/Administraciniai_pastatai_su_100_ir_daugiau_darbuotoj%C5%B3/FeatureServer/25",

    busRoutes: "https://arcgis.sisp.lt/arcgis/rest/services/VT/VT_marsutu_trasos/FeatureServer/0",

    //blackSpots: "https://gis.vplanas.lt/arcgis/rest/services/Interaktyvus_zemelapis/Transportas/MapServer/60",
    blackSpots: "https://gis.vplanas.lt/arcgis/rest/services/Interaktyvus_zemelapis2/Transportas_VMSA/MapServer/66",

    parking: "https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Parkomatai_2024_05_21/FeatureServer/0",

    bikeParking: "https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Tikslios_saugykl%c5%b3_vietos/FeatureServer/0",

    wazeHistory: "https://arcgis.sisp.lt/arcgis/rest/services/Waze/Waze_History_public/FeatureServer/0",

    bikeSensors: "https://arcgis.sisp.lt/arcgis/rest/services/Dviračių_jutiklių_duomenys/FeatureServer/0",

}

  
