import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import services from "./serviceAdditionalDataUrl"
import * as renderInfo from "../PopUpRenderer/renderAdditionalDataLayers"
import { blackSpotsRenderer, parkingRenderer, parkingBikeRenderer, sensorRenderer } from "../PopUpRenderer/renderAdditionalDataLayers"
const bloomStyle = "bloom(0.0, 0.01px, 1%)"



export const schoolPre = new FeatureLayer({
    effect:bloomStyle,
    url: services.schoolPre,
    outFields: ["*"],
    visible: false
})

export const schoolElementary = new FeatureLayer({
    effect:bloomStyle,
    url: services.schoolElementary,
    outFields: ["*"],
    visible: false
})

export const schoolBasic = new FeatureLayer({
    effect:bloomStyle,
    url: services.schoolBasic,
    outFields: ["*"],
    visible: false
})

export const schoolHigher = new FeatureLayer({
    effect:bloomStyle,
    url: services.schoolHigher,
    outFields: ["*"],
    visible: false
})

export const hospital = new FeatureLayer({
    effect:bloomStyle,
    url: services.hospitals,
    outFields: ["*"],
    visible: false
})

export const dentist = new FeatureLayer({
    effect:bloomStyle,
    url: services.dentist,
    outFields: ["*"],
    visible: false
})

export const publicOffice = new FeatureLayer({
    effect:bloomStyle,
    url: services.statePublicOffices,
    outFields: ["*"],
    visible: false,
    minScale: 30000
})

export const busStops = new FeatureLayer({
    effect:bloomStyle,
    url: services.busStopPublic,
    outFields: ["*"],
    renderer: renderInfo.renderCimBusStop(),
    visible: false
})


export const busRoutes = new FeatureLayer({
    effect:bloomStyle,
    url: services.busRoutes,
    outFields: ["*"],
    visible: false
})

export const blackSpots = new FeatureLayer({
    effect:bloomStyle,
    url: services.blackSpots,
    outFields: ["*"],
    visible: false,
    renderer: blackSpotsRenderer
})


export const parking = new FeatureLayer({
    effect:bloomStyle,
    url: services.parking,
    outFields: ["*"],
    visible: false,
    renderer: parkingRenderer,
    minScale: 15000
})

export const parkingBike = new FeatureLayer({
    effect:bloomStyle,
    url: services.bikeParking,
    outFields: ["*"],
    visible: false,
    renderer: parkingBikeRenderer,
    minScale: 15000
})

export const wazeHistory = new FeatureLayer({
    effect:bloomStyle,
    url: services.wazeHistory,
    outFields: ["*"],
    visible: false,
    //minScale: 15000,
    //definitionExpression: "pubmillis >= timestamp'10/18/2024 23:59:59' and pubmillis < timestamp'10/19/2024 23:59:59' and (type = 'HAZARD' or type = 'ACCIDENT')"
    //definitionExpression: "pubmillis >= timestamp'10/08/2024 23:59:59' and pubmillis < timestamp'10/09/2024 23:59:59' and (type = 'HAZARD' or type = 'ACCIDENT')"
})


export const bikeSensors = new FeatureLayer({
    effect:bloomStyle,
    url: services.bikeSensors,
    outFields: ["*"],
    visible: true,
    renderer: sensorRenderer,
    minScale: 15000,
    //definitionExpression: "pubmillis >= timestamp'10/18/2024 23:59:59' and pubmillis < timestamp'10/19/2024 23:59:59' and (type = 'HAZARD' or type = 'ACCIDENT')"
    //definitionExpression: "pubmillis >= timestamp'10/08/2024 23:59:59' and pubmillis < timestamp'10/09/2024 23:59:59' and (type = 'HAZARD' or type = 'ACCIDENT')"
})

