import React, { useContext, useState, useEffect } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";
import "../../../../Judumas.css";
import { bikeSensors, wazeHistory } from "../../../../Services/serviceAdditionalData";
import { checkOneDayApartAndTransform } from "../../../../helpers/helper";


export default function ContextLayers() {

    const[gridToggleArr, setGridToggleArr] = useState([true, true, true, true, true, true, true, true, true, true])
    const[wazeContitions, setWazeConditions] = useState(false)
    //const[wazeQuery, setwazeQuery] = useState(null)
    const lowListLayerUrs = ["https://arcgis.sisp.lt/arcgis/rest/services/VT/VT_marsutu_trasos/FeatureServer", "https://gis.vplanas.lt/arcgis/rest/services/Interaktyvus_zemelapis/Transportas/MapServer"]

    const mapContext = useContext(MapContext);

    function reorderLayers(layer, layerIdentifier, layerIdentifierType) {

      if (layerIdentifierType === 1) {
        const lowPriorityLayer = lowListLayerUrs.find((layerUrl) => layerUrl === layerIdentifier);
        if (lowPriorityLayer ) {
          return 
        }
      }
      mapContext.state.mapView.map.reorder(layer, mapContext.state.mapView.map.layers.length -1 )
    }

    function toggleContextLayerVisibility(layerId) {
        mapContext.state.dataContextLayers.forEach((layer) => {
          if (layerId === layer.layerId) {
            layer.visible = !layer.visible;
            reorderLayers(layer, layerId, 0)
          }
          
        });
      }
    
      function toggleContextLayerVisibilityURL(layerURL) {
        mapContext.state.dataContextLayers.forEach((layer) => {
          console.log(layerURL, layer.url)
          if (layerURL === layer.url) {
            layer.visible = !layer.visible;
            console.log(layer.visible)
            reorderLayers(layer, layerURL, 1)
          }
          
        });
      }

      function toggleDirectvisibility(layer) {
        layer.visible = !layer.visible;
        reorderLayers(layer, layer, 2)
        //console.log(wazeQuery)
        //wazeHistory2.definitionExpression = "pubmillis >= timestamp'10/08/2024 23:59:59' and pubmillis < timestamp'10/09/2024 23:59:59' and (type = 'HAZARD' or type = 'ACCIDENT')"
        /*wazeHistory.queryFeatures(wazeQuery).then((result) => {
          console.log("Filtered features:", result.features)
        })*/
      }

      useEffect(() => {
        console.log(gridToggleArr)
        
          let length = gridToggleArr.length
          
          if (wazeContitions === false) {
            length--
          }  
          console.log(length)
          for (let i = 0; i< length; i++){
            console.log(i)
            if (gridToggleArr[i] === true) {
              let str = "gridToggle" + i
              document.getElementById(str).click();
            }
          }
        
      },[])

      /*
            mapContext.state.dataContextLayers.forEach((layer) => {
              if (wazeHistory.url === layer.url) {
                layer.definitionExpression  = result.transformedString + " and (type = 'HAZARD' or type = 'ACCIDENT')"
                console.log(layer.definitionExpression)
                layer.visible = !layer.visible;
                console.log(layer.visible)
              }
            });
            */
      
      useEffect(() => {
        console.log("ENTER")
        if (mapContext.state.data === "analytics") {
          console.log(" if (mapContext.state.data === analytic) TRUE")
          let result = checkOneDayApartAndTransform(mapContext.state.dateRange)
          if (result.isOneDayApart === true) {
            
            //wazeHistory.definitionExpression = "pubmillis >= timestamp'10/08/2024 23:59:59' and pubmillis < timestamp'10/09/2024 23:59:59' and (type = 'HAZARD' or type = 'ACCIDENT')"
            
            
            console.log(" true FALSE")
           wazeHistory.definitionExpression = result.transformedString + " and (type = 'HAZARD' or type = 'ACCIDENT')"
           setWazeConditions(true)
            
          } else 
          {
            console.log(" if (result.isOneDayApart. ) FALSE")
            wazeHistory.definitionExpression = null
            setWazeConditions(false)
          }
        } else {
          console.log(" if (mapContext.state.data === analytic) FALSE")
          wazeHistory.definitionExpression = null
          setWazeConditions(false)
        }
        console.log(mapContext.state.dateRange)
      },[mapContext.state.data, mapContext.state.dateRange])
      
      
      useEffect(() => {
        if (wazeContitions === true && wazeHistory.visible === false) {
          document.getElementById("gridToggle8").click();
        }
        console.log(wazeHistory.definitionExpression)
        console.log(mapContext.state.dateRange)
      }, [wazeContitions])
      
      
      useEffect(() => {
        console.log(mapContext.state.dateRange)
      }, [mapContext.state.dateRange])
    

      

    return (
        <>
            
            <table className="ToggleTable">
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"JAR objektai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle0"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[0] = !arr[0]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://opencity.vplanas.lt/server/rest/services/Hosted/Administraciniai_pastatai_su_100_ir_daugiau_darbuotoj%C5%B3/FeatureServer");
                        
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Ugdymo įstaigos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle1"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[1] = !arr[1]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibility(30);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Sveikatos priežiūros įstaigos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle2"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[2] = !arr[2]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibility(28);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"VT stotelės"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle3"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[3] = !arr[3]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Stotel%c4%97s/FeatureServer");
                      }}
                    />
                    <span class="slider round" ></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"VT maršrutai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle4"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[4] = !arr[4]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://arcgis.sisp.lt/arcgis/rest/services/VT/VT_marsutu_trasos/FeatureServer");
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Juodosios dėmės"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle5"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[5] = !arr[5]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://gis.vplanas.lt/arcgis/rest/services/Interaktyvus_zemelapis2/Transportas_VMSA/MapServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Parkomatai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle6"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[6] = !arr[6]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Parkomatai_2024_05_21/FeatureServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Dviračių saugyklos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle7"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[7] = !arr[7]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Tikslios_saugykl%c5%b3_vietos/FeatureServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Dviračių davikliai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle8"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        console.log(mapContext.state.dataContextLayers.length)
                        // VN Pakeisti kai pridedamas naujas context layeris, kadangi reikalavimas yra jog prisijungimo lentele spaustu tik aktyvavus sluoksni jo negalima buvo prideti app.js kur sudedami visi context layeriai.
                        if (mapContext.state.dataContextLayers.length === 13) {
                          mapContext.dispatch({
                            dataContextLayers: [...mapContext.state.dataContextLayers, bikeSensors]
                          });
                          mapContext.state.mapView.map.add(bikeSensors)
                        }
                        let arr = gridToggleArr
                        arr[8] = !arr[8]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://arcgis.sisp.lt/arcgis/rest/services/Dviračių_jutiklių_duomenys/FeatureServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              { (wazeContitions === true) ?
                <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Waze duomenys"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle9"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[9] = !arr[9]
                        setGridToggleArr(arr)
                        toggleDirectvisibility(wazeHistory);  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr> : <></>
              }
                </table>
            
        </>
    )
}