import React, { useEffect, useRef, useContext, useState, forwardRef } from "react";
import "../widgets/widgetcss/styles.css";
import Chart, { LogarithmicScale } from "chart.js/auto";
import { MapContext } from "../../../../MapContext";
import DatePicker, {registerLocale} from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import uniqueValues from "@arcgis/core/smartMapping/statistics/uniqueValues";
import esriConfig from "@arcgis/core/config";
import Query from "@arcgis/core/rest/support/Query";
import RelationshipQuery from "@arcgis/core/rest/support/RelationshipQuery";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter";
import Zoom from "@arcgis/core/widgets/Zoom";
import { operatorAvailableDates, meteoLayer, operator } from "../../../../Services/serviceLayers";
import "../../../../Judumas.css"
import lt from "date-fns/locale/lt"
import { checkOneDayApartAndTransform, toISOLocal, transformString } from "../../../../helpers/helper"
import ContextLayers from "./ContextLayers";
import { queryFeaturesOverLimit2k, generateDateScoreFilterFromString} from "../../../../helpers/helper";
import { wazeHistory } from "../../../../Services/serviceAdditionalData";


registerLocale("lt", lt); 

const chartAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw(chart, args, options) {
    const {ctx, chartArea: {left, top, width, height}} = chart;
    //console.log(left, top, width, height)
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.setLineDash(options.borderDash || []);
    ctx.lineDashOffset = options.borderDashOffset;
    ctx.strokeRect(left, (top + height), width, 0);
    ctx.restore();
  }
};

export default function Sidebar({ data, token }) {
  const mapContext = useContext(MapContext);
  const mapFeatureLayer = mapContext.state.featureLayer;
  const [dateRange, setDateRange] = useState([null, null]);
  const [availableDates, setAvailableDates] = useState([]);
  const [filterView, setFilterView] = useState(null);
  const [filterFlag, setFilterFlag] = useState(null);
  const [startDate, endDate] = dateRange;
  const [initialRender, setInitialRender] = useState(0);
  const [totalUsage, setTotalUsage] = useState(0);
  const [datePickerState, setDatePickerState] = useState(0);
  const [size, setSize] = useState(false);

  const[weatherChartEnabled, setWeatherChart] = useState(false);
  const[weatherData, setWeatherData] = useState([]);

  var diagram = useRef(null);
  var bubbleDiagram = useRef(null);
  var monthDiagram = useRef(null);

  function changeSize() {
    if (size === false ){
      
      document.getElementById('AnalyticsSidebar').style.width = 44 + 'vw'
      document.getElementById('HideSidebarBtn').style.right = 47.5 + 'vw'
      setSize(true)
    } else {
      document.getElementById('AnalyticsSidebar').style.width = 22 + 'vw'
      document.getElementById('HideSidebarBtn').style.right= 25.5 + 'vw'
      setSize(false)
    }
   

  }

  useEffect(() => {
    if (dateRange[0] === null) {
      data[2] = null;
      return;
    }
    if (dateRange[1] === null) {
      //let day = new Date(dateRange[0])
      //day.setDate(day.getDate() + 1)
      //mapContext.state.featureLayer.definitionExpression = "datetime >= '" + dateRange[0].toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) + "' and datetime <= '" + day.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) + "'";
      //mapContext.state.featureLayer.visible = true;
    } else if (1 == 1) {
      
      let day = new Date(dateRange[0]);
      day.setDate(day.getDate() - 1);
      let dateRangeLocal =
        "datetime >= timestamp'" +
        day.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) +
        " 23:59:59' and datetime < timestamp'" +
        dateRange[1].toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) +
        " 23:59:59'";
      //"= timestamp" + mapContext.state.dateRange.substring(16, 27) + " 18:00:00'";
      mapContext.dispatch({
        dateRange: dateRangeLocal,
      });
      let filterByDate = transformString(dateRangeLocal, 'datetime', 'created_at')
      console.log(filterByDate)
      //console.log(mapContext.state.mapView)
      //operator.definitionExpression = filterByDate
      //created_at >= timestamp'12/31/2023 23:59:59' and created_at < timestamp'01/31/2024 23:59:59'
      for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
        console.log(mapContext.state.mapView.layerViews.items[i].layer.url)
        let str
        
        if (mapContext.state.mapView.layerViews.items[i].layer.layerId === 0 && mapContext.state.transportation.toLowerCase() === "car" && mapContext.state.mapView.layerViews.items[i].layer.url === operator.url) {
          mapContext.state.mapView.layerViews.items[i].filter = {
            //where: "name <> 'None' or service <> 'None'",
            //where: filterByDate
            where: "highway IN ('motorway',  'trunk',  'primary',  'secondary',  'tertiary',  'unclassified',  'residential',  'service',  'motorway_link',  'trunk_link',  'primary_link',  'secondary_link',  'motorway_junction', 'living_street', 'tertiary_link', 'living_street')"// and " + filterByDate
          };
          if (dateRangeLocal !== null) {
            str = generateDateScoreFilterFromString(dateRangeLocal);
            mapContext.state.mapView.layerViews.items[i].filter = {
              where: mapContext.state.mapView.layerViews.items[i].filter.where + str
            };
          }
          console.log("Filter OSM TEST")
          console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
        }
        if (mapContext.state.mapView.layerViews.items[i].layer.layerId === 0 && mapContext.state.transportation.toLowerCase() === "bike" && mapContext.state.mapView.layerViews.items[i].layer.url === operator.url) {
          
          mapContext.state.mapView.layerViews.items[i].filter = {
            //where: "name <> 'None' or service <> 'None'",
            //where: "highway IN ('track', 'path', 'cycleway', 'footway', 'motorway',  'trunk',  'primary',  'secondary',  'tertiary',  'unclassified',  'residential',  'service',  'motorway_link',  'trunk_link',  'primary_link',  'secondary_link',  'motorway_junction', 'living_street')"
            where: "highway IN ('motorway',  'trunk',  'primary',  'secondary',  'tertiary',  'unclassified',  'residential',  'service',  'motorway_link',  'trunk_link',  'primary_link',  'secondary_link',  'motorway_junction', 'living_street', 'tertiary_link', 'living_street')"// and " + filterByDate

          };
          if (mapContext.state.dateRange !== null) {
            str = generateDateScoreFilterFromString(mapContext.state.dateRange);
            mapContext.state.mapView.layerViews.items[i].filter = {
              where: mapContext.state.mapView.layerViews.items[i].filter.where + str
            };
          }
          console.log("Filter OSM TEST")
          console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
        }
      }
      
      //console.log(mapContext.state.mapView)
      mapContext.state.featureLayer.visible = true;

    } else {
      let day = new Date(dateRange[1]);
      day.setDate(day.getDate() + 1);
      mapContext.state.featureLayer.visible = true;
      /*
      mapContext.state.featureLayer.definitionExpression = "datetime >= '" + dateRange[0].toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) + "' and datetime <= '" + day.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) + "'";
      mapContext.state.featureLayer.visible = true;
      */
      let localLayer = mapContext.state.dataFeatureLayer;
      let query = localLayer.createQuery();
      console.log("QUERIES")
      let dateRangeLocal =
        "datetime >= date'" +
        dateRange[0].toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) +
        "' and datetime < date'" +
        day.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) +
        "'";

      mapContext.dispatch({
        dateRange: dateRangeLocal,

      });


      console.log("Filter OSM TEST")
      for (let i = 0; i < mapContext.state.mapView.layerViews.items.length; i++){
        if (dateRangeLocal !== null && mapContext.state.mapView.layerViews.items[i].layer.url === operator.url) {
          let str = generateDateScoreFilterFromString(mapContext.state.dateRange);
          mapContext.state.mapView.layerViews.items[i].filter = {
            where: mapContext.state.mapView.layerViews.items[i].filter.where + str
          };
          console.log(mapContext.state.mapView.layerViews.items[i].filter.where)
        }
      }
      


      query.where = dateRangeLocal;
      query.outFields = ["parent_global_id"];
      query.returnDistincValues = true;
      mapContext.state.dataFeatureLayer.queryFeatureCount(query).then(function (numFeatures) {
        // prints the total count to the console
      });
      localLayer.queryObjectIds(query).then(async function (response) {


        let objectIds = response.features.map((a) => a.attributes.OBJECTID);

        let obj = mapContext.state.dataFeatureLayer.queryRelatedFeatures({
          outFields: ["OBJECTID"],
          relationshipId: mapContext.state.dataFeatureLayer.relationships[0].id,
          objectIds: objectIds,
        });

        let objectIdsParsed = [];
        for (const [key, value] of Object.entries(await obj)) {
          objectIdsParsed.push(value.features[0].attributes.OBJECTID);
        }
        // let objectIdsParsed = obj.uniqueValueInfos.map(a => a.value);

        const filter = new FeatureFilter({
          objectIds: objectIdsParsed,
        });
        mapContext.state.mapView.layerViews.items[0].filter = filter;

        // returns a feature set with features containing the following attributes
        // STATE_NAME, COUNTY_NAME, POPULATION, POP_DENSITY
      });
    }
  }, [dateRange]);

  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !==null) {
      let datesSelected = dateRange[1] - dateRange[0]
      if (datesSelected <= 604800000 && datesSelected >= 0) {
        console.log("Trigger Weather query")
          let day = new Date(dateRange[0]);
          day.setDate(day.getDate() - 1);
          let dateRangeLocal =
            "observationTimeUtc >= timestamp'" +
            day.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) +
            " 23:59:59' and observationTimeUtc < timestamp'" +
            dateRange[1].toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) +
            " 23:59:59'";

          var query = meteoLayer.createQuery();
          query.where = dateRangeLocal;
          query.outFields = ["*"];
          console.log("weatherQuery")
          console.log(query)
          //meteoLayer.queryFeatures(query)
          queryWeather(query)
          

      } else {
        console.log("Ignore Weather query")
        setWeatherData([])
        setWeatherChart(false)
      }
    }

    async function queryWeather(query) {
      const waetherFeatures = await queryFeaturesOverLimit2k(meteoLayer, query)
      console.log("waetherFeatures")
      console.log(waetherFeatures)
      if (waetherFeatures.length > 0 && waetherFeatures.length <= 25) {
        console.log(waetherFeatures.length)
        setWeatherData(waetherFeatures)
        setWeatherChart(1)
        console.log(weatherChartEnabled)
      } else if (waetherFeatures.length > 25) {
        console.log(waetherFeatures.length)
        setWeatherData(waetherFeatures)
        setWeatherChart(2)
        console.log(weatherChartEnabled)
      } else {
        console.log(waetherFeatures.length)
        setWeatherData([])
        setWeatherChart(false)
      }
    }
  },[dateRange])

  // Oro salygu stebejimas
  useEffect(() => {
    console.log(weatherData)
    console.log(weatherChartEnabled)
  },[weatherData, weatherChartEnabled])
  

  useEffect(() => {
    mapContext.state.featureLayer.visible = true;
    
    return;
  }, [mapContext.state.clearFlag]);

  useEffect(() => {
    if (filterFlag != 0) {
      return;
    }

    mapContext.state.mapView.layerViews.items[0].filter = filterView;

    if (filterFlag == 0) {
      setFilterFlag(1);
    }

  }, [filterView]);

  useEffect(() => {
    console.log("ENTER")
    
      console.log(" if (mapContext.state.data === analytic) TRUE")
      let result = checkOneDayApartAndTransform(mapContext.state.dateRange)
      if (result.isOneDayApart === true) {
        
        //wazeHistory.definitionExpression = "pubmillis >= timestamp'10/08/2024 23:59:59' and pubmillis < timestamp'10/09/2024 23:59:59' and (type = 'HAZARD' or type = 'ACCIDENT')"
        
        
        console.log(result.transformedString)
       wazeHistory.definitionExpression = result.transformedString + " and (type = 'HAZARD' or type = 'ACCIDENT')"
       //setWazeConditions(true)
        
      } else 
      {
        console.log(" if (result.isOneDayApart. ) FALSE")
        wazeHistory.definitionExpression = null
        wazeHistory.visible = false
        //(false)
      }
    
    console.log(mapContext.state.dateRange)
  },[ mapContext.state.dateRange])

  useEffect(() => {
    console.log("Redraw")
    console.log("Diagdata")
    console.log(data[2], data[3])
    if (data[2] == null) {
      return;
    } else {
      console.log("data")
      console.log(data)
      let totalData = 0;
      let datasetLabel = [];
      for (let i = 0; i < 24; i++) {
        datasetLabel.push(i + ":00");
      }

      let dataSet = [];
      for (let i = 0; i < 24; i++) {
        dataSet.push(0);
      }

      let weekDayUsage = [];
      for (let i = 0; i < 7; i++) {
        weekDayUsage.push({ x: (i + 1) * 100, y: 50, r: 0 });
      }

      let monthUsage = [];
      for (let i = 0; i < 12; i++) {
        monthUsage.push(0);
      }
      let monthLabels = ["Sau", "Vas", "Kov", "Bal", "Geg", "Bir", "Lie", "Rgp", "Rgs", "Spa", "Lap", "Gru"];
      console.log("data[2]")
      console.log(data[2])
      for (let i = 0; i < Object.values(data[2]).length; i++) {
        //First graph
        let key = Object.keys(data[2])[i];
        //console.log(key)
        let char = String.fromCharCode(8239)
        let splitKeyOut = Object.keys(data[2])[i].split(" ");
        let splitKey
        if (splitKeyOut.length === 3) {
          splitKey = Object.keys(data[2])[i].split(" ");
        } else {
          let splitKeyInner = splitKeyOut[1].split(char);
          splitKey = [splitKeyOut[0], splitKeyInner[0],  splitKeyInner[1]]
        }
        
        //console.log(splitKey)

        let hour = parseInt(splitKey[1]);
        if (splitKey[2].includes("PM") && hour != 12) {
          hour += 12;
        } else if (splitKey[2].includes("AM") && hour == 12) {
          hour -= 12;
        }
        dataSet[hour] += data[2][key];
        totalData += data[2][key];
        //Second graph
        let weekDay = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getDay();
        if (weekDay == 0) {
          weekDay = 6;
        } else {
          weekDay -= 1;
        }
        weekDayUsage[weekDay].r += data[2][key];

        // Third graph Dataset
       
        let month = new Date(splitKey[0].substring(0, splitKey[0].length - 1)).getMonth();
        monthUsage[month] += data[2][key];
      }

      setTotalUsage(totalData);
      let weekDayUsageValuesPercent = structuredClone(weekDayUsage) ;
      for (let i = 0; i < 7; i++) {
        weekDayUsageValuesPercent[i].r = (weekDayUsageValuesPercent[i].r / totalData).toFixed(4) * 100;
        weekDayUsage[i].r = (weekDayUsage[i].r / totalData).toFixed(4) * 35;
      }

      const labels = ["P", "A", "T", "K", "Pn", "Š", "S"];
      //console.log(weekDayUsage)
      if (weatherChartEnabled === false || weatherChartEnabled === 2 ) {
        var myChart = new Chart(diagram.current, {
          type: "bar",
          data: {
            labels: datasetLabel,
            //labels: Object.keys(data[2]),
            datasets: [
              {
                label: "srautas",
                backgroundColor: "rgb(133, 188, 25)",
                //data: Object.values(data[2]),
                data: dataSet,
                barThickness: 10,
                borderWidth: 1,
              },
            ],
          },
          
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,
            scales: {
              y: {
                grid: { color: "#EFEFEF" },
                ticks: {
                  font: {
                    weight: 600,
                    family: 'Poppins'
                  },
                  color:  "#EFEFEF"
                },
                beginAtZero: true,
              },
              x: {
                ticks:{
                  font: {
                    weight: 600,
                    family: 'Poppins'
                  },
                  color: "#EFEFEF"
                }
              }
            },
          },
        });
      } else {
        console.log("Double")
        const hourlyPrecipitation = Array(24).fill(0);

        weatherData.forEach(dataPoint => {
          const attributes = dataPoint.attributes;
          
          const date = new Date(attributes.observationTimeUtc);

          const hour = date.getUTCHours();

          hourlyPrecipitation[hour] += attributes.precipitation || 0;
        });
        console.log(hourlyPrecipitation)
        var myChart = new Chart(diagram.current, {
          data: {
            labels: datasetLabel,
            //labels: Object.keys(data[2]),
            datasets: [
              {
                type: 'line',
                label: 'Krituliai mm',
                data: hourlyPrecipitation,
                borderColor: 'rgb(54, 162, 235)',
                yAxisID: 'y1'
              },
              {
                type: 'bar',
                label: "srautas",
                backgroundColor: "rgb(133, 188, 25)",
                //data: Object.values(data[2]),
                data: dataSet,
                barThickness: 10,
                borderWidth: 1,
                yAxisID: 'y'
              }
            ],
          },
          
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,
            scales: {
              y: {
                grid: { color: "#EFEFEF" },
                ticks: {
                  font: {
                    weight: 600,
                    family: 'Poppins'
                  },
                  color:  "#EFEFEF"
                },
                beginAtZero: true,
              },
              y1: {
                id: 'y1',
              type: 'linear',
              display: true,
              position: 'left',
              beginAtZero: true,
              // grid line settings
              grid: {
                
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
              ticks: {
                font: {
                  color:  "rgb(133, 188, 25)",
                  weight: 600,
                  family: 'Poppins'
                },
                color:  "rgb(54, 162, 235)"
              },
              },
              x: {
                ticks:{
                  font: {
                    weight: 600,
                    family: 'Poppins'
                  },
                  color: "#EFEFEF"
                }
              }
            },
          },
        });
      }
      
      if (weatherChartEnabled === false || weatherChartEnabled === 1 ) {
        var secondChart = new Chart(bubbleDiagram.current, {
          type: "bubble",
          data: {
            datasets: [
              {
                
                backgroundColor: "rgb(133, 188, 25)",
                data: weekDayUsage,
                radius: 1,
                
              },
            ],
          },
          options: {
            
            events: [],
            maintainAspectRatio: false,
            plugins: {
              chartAreaBorder: {
                borderColor: 'white',
                borderWidth: 2
              },

              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                display: false,
                min: 0,
                max: 100,
              },
              x: {
                border: {
                  display: true
                },
                grid: {
                  display: false
                },
                display: true,
                min: 0,
                max: 800,
                afterBuildTicks: (axis) => (axis.ticks = [100, 200, 300, 400, 500, 600, 700].map((v) => ({ value: v }))),
                ticks: {
                  font: {
                    weight: 600,
                    family: 'Poppins'
                  },
                  
                  maxRotation: 0,
                  minRotation: 0,
                  color: "#EFEFEF",
                  // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                  callback: function (val, index) {
                    // Hide every 2nd tick label
                    return [labels[index] , weekDayUsageValuesPercent[index].r.toFixed(0) + "%"];
                  },
                },
              },
            },
          },
          plugins: [chartAreaBorder]
        });
      } else {
        const weeklyPrecipitation = Array(7).fill(0);

        weatherData.forEach(dataPoint => {
          const attributes = dataPoint.attributes;
          
          const date = new Date(attributes.observationTimeUtc);

          let dayOfWeek = date.getUTCDay();

          dayOfWeek = (dayOfWeek === 0) ? 6 : dayOfWeek - 1;

          weeklyPrecipitation[dayOfWeek] += attributes.precipitation || 0;
        });
        const bubbleWeatherData = [
          { x: 100, y: weeklyPrecipitation[0], r: 10 }, // Monday
          { x: 200, y: weeklyPrecipitation[1], r: 10 }, // Tuesday
          { x: 300, y: weeklyPrecipitation[2], r: 10 }, // Wednesday
          { x: 400, y: weeklyPrecipitation[3], r: 10 }, // Thursday
          { x: 500, y: weeklyPrecipitation[4], r: 10 }, // Friday
          { x: 600, y: weeklyPrecipitation[5], r: 10 }, // Saturday
          { x: 700, y: weeklyPrecipitation[6], r: 10 }, // Sunday
        ];
        console.log("weeklyPrecipitation")
        console.log(weeklyPrecipitation)
        var secondChart = new Chart(bubbleDiagram.current, {
          type: "bubble",
          data: {
            datasets: [
              {
                type: 'line',
                label: 'Krituliai mm',
                data: bubbleWeatherData,
                borderColor: 'rgb(54, 162, 235)',
                yAxisID: 'y1'
              },
              {
                
                backgroundColor: "rgb(133, 188, 25)",
                data: weekDayUsage,
                radius: 1,
                yAxisID: 'y'
                
              },
            ],
          },
          options: {
            
            events: [],
            maintainAspectRatio: false,
            plugins: {
              chartAreaBorder: {
                borderColor: 'white',
                borderWidth: 2
              },

              legend: {
                display: false,
              },
            },
            scales: {
              
              y: {
                display: false,
                min: 0,
                max: 100,
              },
              y1: {
                id: 'y1',
              type: 'linear',
              display: true,
              position: 'left',
              beginAtZero: true,
              // grid line settings
                grid: {
                  
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                  font: {
                    color:  "rgb(133, 188, 25)",
                    weight: 600,
                    family: 'Poppins'
                  },
                  color:  "rgb(54, 162, 235)"
                },
              },
              x: {
                border: {
                  display: true
                },
                grid: {
                  display: false
                },
                display: true,
                min: 0,
                max: 800,
                afterBuildTicks: (axis) => (axis.ticks = [100, 200, 300, 400, 500, 600, 700].map((v) => ({ value: v }))),
                ticks: {
                  font: {
                    weight: 600,
                    family: 'Poppins'
                  },
                  
                  maxRotation: 0,
                  minRotation: 0,
                  color: "#EFEFEF",
                  // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                  callback: function (val, index) {
                    // Hide every 2nd tick label
                    return [labels[index] , weekDayUsageValuesPercent[index].r.toFixed(0) + "%"];
                  },
                },
              },
            },
          },
          plugins: [chartAreaBorder]
        });
      }

      var monthChart = new Chart(monthDiagram.current, {
        type: "bar",
        data: {
          labels: monthLabels,
          //labels: Object.keys(data[2]),
          datasets: [
            {
              backgroundColor: "rgb(133, 188, 25)",
              barThickness: 20,
              label: "srautas",
              //data: Object.values(data[2]),
              data: monthUsage,
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y: {
              grid: { color: "#EFEFEF" },
              ticks:{
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color: "#EFEFEF"
              },
              beginAtZero: true,
            },
            
            x:{
              
              ticks:{
                font: {
                  weight: 600,
                  family: 'Poppins'
                },
                color: "#EFEFEF"
              }
            }
          },
        },
      });
      {

    }
    }
    return () => {
      myChart.destroy();
      secondChart.destroy();
      monthChart.destroy();
      //doughnutChart.destroy()
    };
  }, [data[2], data[3]]);

  useEffect(() => {
    //mapContext.state.mapView.zoom >= 14
    console.log(mapContext.state.mapView.graphics)
    console.log(mapContext.state.mapView.zoom)
  },[mapContext.state.mapView.zoom])

  useEffect(() => {
    if (initialRender === 0) {
      mapContext.dispatch({
        selectedStreetArray: [],
      });
      mapContext.dispatch({
        dateRange: 0,
      });
      console.log(mapContext.state.mapView.graphics)
      mapContext.state.mapView.graphics.removeAll();
      console.log(mapContext.state.mapView.graphics)

      if (mapContext.state.dateWithData != null) {
        setAvailableDates(mapContext.state.dateWithData);
        setInitialRender(1)
        return;
      }

      esriConfig.request.interceptors.push({
        // set the `urls` property to the URL of the FeatureLayer so that this
        // interceptor only applies to requests made to the FeatureLayer URL
        urls: [mapContext.state.dataurl],

        error: function (error) {
          //console.log(error)
        },

        before: async function (params) {
          params.requestOptions.query.token = token;
          params.requestOptions.query = params.requestOptions.query || {};
        },
      });
      let uniqueDates = [];
      //console.log("UniqueValues")
      uniqueValues({
        layer: operatorAvailableDates,
        field: "date_str",
      }).then(function (response) {
        // prints each unique value and the count of features containing that value
        let infos = response.uniqueValueInfos;
        let availableDatesLocal = [];
        let parsedDates = [];
        infos.forEach(function (info) {
          //console.log((info.value))
          //console.log( Math.floor(info.value/1000/60/60/24))
          //info.value = Math.floor((info.value) / 1000 / 60 / 60 / 24) * 24 * 60 * 60 * 1000;
          //info.value = info.value
          //console.log("date:" + info.value);
          //let addDate = new Date(info.value)

          if (!availableDatesLocal.includes(info.value) && info.value != null) {
            //let addDate = new Date(info.value)
            //console.log(date)
            let splitDate = info.value.split('/')
            let addDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
            parsedDates.push(addDate);
            availableDatesLocal.push(info.value);
            //setAvailableDates(availableDatesLocal)
            //console.log(availableDatesLocal)
          }
        });
        
        /*availableDatesLocal.forEach((date) => {
          let addDate = new Date(date);
          
        });*/
        //console.log(parsedDates)
        setAvailableDates(parsedDates);
        mapContext.dispatch({
          dateWithData: parsedDates,
        });
        //console.log(availableDates)
      });

      
      setInitialRender(1);
    }
  });




  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelectorAll(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelectorAll(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  waitForElm(".SidebarBtn").then((button) => {
    button.forEach((element) => {
      element.addEventListener("click", function () {
        button.forEach((nav) => nav.classList.remove("Active"));
        this.classList.add("Active");
      });
    });
  });

  const today = moment();
  const disableFutureDt = (current) => {
    return current.isBefore(today);
  };

  const setCalndarBtn = (option) => {
    console.log(mapContext.state.featureLayer.renderer)
    let availCpy = [...availableDates]
   //console.log(availCpy)
    let firstDate = new Date(availCpy[availCpy.length-1])
    //console.log(firstDate)
    switch(option) {
    case 'Day':
      firstDate.setDate(firstDate.getDate());
      break;
    case 'Week':
      firstDate.setDate(firstDate.getDate() - 7);
      break;
    case 'Month':
      firstDate.setMonth(firstDate.getMonth() - 1);
      break;
    default:
    }
    setDateRange([firstDate , availCpy[availCpy.length-1]])
  }

  

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="DatePickerInput" onClick={onClick} ref={ref}>
      {value != "" ? value  : "Pasirinkite iš kalendoriaus"}
    </button>
  ));

  function showContextData( bool) {
    if (bool === true) {
      document.getElementById("AnalyticsSidebar").style.display = "none"
    } else {
      document.getElementById("AnalyticsSidebar").style.display = "block"
    }
    mapContext.dispatch({
      contextDataVisible: bool,
    });
  }

  waitForElm('.GreyCard').then((button) => {
    button.forEach(element => {
        element.addEventListener("click",function(){
          button.forEach(nav => nav.classList.remove("Active"))
          this.classList.add("Active")
        })
      })
  });



  useEffect(() => {
    if (totalUsage < 0) {
      setTotalUsage(0)
      diagram.destroy()
      bubbleDiagram.destroy()
      monthDiagram.destroy()
    }
  },[totalUsage])

  

  /*
   <DatePicker
              selectsRange={true}
              {startDate={startDate}}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
            />
   */
  return (
    <>
    <div className="GreyCardWrapper" id="CompareCardWrapper">
      <button className="GreyCard LeftmostCard Active" onClick={() => {showContextData(false)}}>Analitika</button>
      <button className="GreyCard" id="GreyCardContext" onClick={() => {showContextData(true)}}>Kontekstiniai Duomenys</button>
    </div>
    
      <div className="Sidebar" id="AnalyticsSidebar">
        
        <div className="">
          {"Pasirinkite laikotarpį:"}
          
          {/*<h1>{data[2] !== null ? data[0] : ""}</h1>*/}
        </div>
        
        { availableDates.length === 0 ? <div class="loader"></div> :
                <>
              <button className=' ClearBtn SidebarBtn'onClick={() => {setCalndarBtn("Month")}}>Mėnuo</button>
              <button className=' ClearBtn SidebarBtn'onClick={() => {setCalndarBtn("Week")}}>Savaitė</button>
              <button className=' ClearBtn SidebarBtn' onClick={() => {setCalndarBtn("Day")}}>Diena</button>
              </>}
        {/*<button className=' ready1' onClick={()=> {queryDate()}}>filter</button>*/}
        { datePickerState === 1 ? <><DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          customInput={<ExampleCustomInput/>}
          calendarClassName="DatePickerWrapper"
          selectsDisabledDaysInRange={true}
          inline = {true}
          includeDates={availableDates}
          placeholderText="YYYY-MM-DD"
          locale={"lt"}
          disabledKeyboardNavigation
          onChange={(update) => {
            setDateRange(update);
            waitForElm(".SidebarBtn").then((button) => {
                  button.forEach((nav) => nav.classList.remove("Active"));
            });
          }}
        />
        <button className=' ClearBtn DatePickerClose' onClick={() => {setDatePickerState(0)}}>^</button>
        </> : <button className=' ClearBtn DatePickerOpen MarginBtn' onClick={() => {setDatePickerState(1) }}>{ (dateRange[0] == null || dateRange[1] == null) ? "Kalendorius" : 
        toISOLocal(startDate).split('T')[0].replaceAll('-', '/')  + " - "+toISOLocal(endDate).split('T')[0].replaceAll('-', '/')
        }</button> }

        { mapContext.state.sideBarRequestPending === 1  ? (
          <div class="loader loaderCentered"></div>
        ) : data[0] === "Paspauskite ant linijos" ? <></> : (
          <>
            <div>{"Bendras srautas: " + totalUsage}</div>
            <></>
            {

            <>
            <div className="block diagram">
              <canvas id="analyticsChart" ref={diagram}></canvas>
            </div>
            <div className="block diagram">
              <canvas id="analyticsChartWeek" ref={bubbleDiagram}></canvas>
            </div>
            </>

            }
            <div className="block diagram">
              <canvas id="analyticsChartMonth" ref={monthDiagram}></canvas>
            </div>
            <button className="NonActive LengthBtn MarginBtn" id="ChangeSize" onClick={() => {changeSize()}}>{size ? "Sumažinti" : "Padidinti"}</button>
          </>
          
        )}
        
      </div>
    { mapContext.state.contextDataVisible === false ?
    <></>
      :
      <div id={"SidebarGridSidebar"} className={"MenuColor"}>
          <ContextLayers></ContextLayers>
      </div>
    }
    </>
  );
}
