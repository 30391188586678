import services from "./services"
import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import * as renderInfo from "../PopUpRenderer/renderPopUp"
import Polyline from "@arcgis/core/geometry/Polyline";
   const bloomStyle = "bloom(0.0, 0.01px, 1%)"
   const dropShadow =  "drop-shadow(3px, 3px, 4px)"
 
  export const strava_bike_day = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_bike_day_url,
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    renderer: renderInfo.extentOutline,
    visible: false
  })
  export const strava_bike_year = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_bike_year_url,
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    renderer: renderInfo.trailsRendererStravaBikes,
    visible: false
  })
  export const strava_bike_week = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_bike_week_url,
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    renderer: renderInfo.trailsRendererStravaBikes,
    visible: false
  })

  export const strava_bike_month = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_bike_month_url,
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    renderer: renderInfo.trailsRendererStravaBikes,
    visible: false
  })

  export const waze_jams = new FeatureLayer({
    effect:bloomStyle,
    url: services.waze_jams,
    outFields: ["length","timestamp", "end_time"],
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpWaze,
    renderer: renderInfo.trailsRendererWaze,
    definitionExpression:"date <= date'08/01/2022' and date >= date'06/01/2022'",
    visible: false
  })
  export const strava_pedestrian_month = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_pedest_month_url,
    outFields: ["*"],

    popupTemplate: null,
    renderer: renderInfo.trailsRendererStravaPedestrians(),

    //definitionExpression:"date = date'07/01/2022'",
    // definitionExpression: "month = '2022-05'",
    visible: false
    
  })

  export const strava_pedestrian_day = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_pedest_day_url,
    outFields: ["*"],
    popupTemplate: null,
    renderer: renderInfo.trailsRendererStravaPedestrians(),
    // definitionExpression:"date = date'07/01/2022'",
    // definitionExpression: "month = '2022-05'",
    visible: false
    
  })
  export const strava_pedestrian_week = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_pedest_week_url,
    outFields: ["*"],
    popupTemplate: null,
    renderer: renderInfo.trailsRendererStravaPedestrians(),
    // definitionExpression:"date = date'07/01/2022'",
    // definitionExpression: "month = '2022-05'",
    visible: false
    
  })
  export const strava_pedestrian_year = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_pedest_year_url,
    outFields: ["*"],
    popupTemplate: null,
    renderer: renderInfo.trailsRendererStravaPedestrians(),
    // definitionExpression:"date = date'07/01/2022'",
    // definitionExpression: "month = '2022-05'",
    visible: false
    
  })
  export const strava_month_bikes_old = new FeatureLayer({
    effect:bloomStyle,
    url: services.strava_bike_month_url,
    outFields: ["sum_total","month","date"],
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    renderer: renderInfo.trailsRendererStravaBikes,
    definitionExpression:"date = date'07/01/2022'",
    // definitionExpression: "date >= date'"+ today.toLocaleDateString()+"' and date <= date'"+nextday.toLocaleDateString()+"'",
    visible: false
  })

  export const operator = new FeatureLayer({
    // source: services.operator,
    url: services.operator,
    outFields: ["*"],
    geometryType: "polyline",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpOperator,
    renderer: renderInfo.analyticsRenderer(2.5, 2, 1.5, 1),
    maxScale: 0,
    definitionExpression:"highway IN ('motorway', 'motorway_link', 'primary', 'primary_link', 'trunk', 'trunk_link', 'secondary', 'secondary_link', 'tertiary', 'tertiary_link', 'unclasified', 'living_street', 'residential', 'motorway_junction', 'service', 'unclassified')",
    //definitionExpression: "created_at >= timestamp'09/13/2023 23:59:59' and created_at < timestamp'10/15/2023 23:59:59'",
    visible: false
  })

  export const operatorOuline = new FeatureLayer({
    // source: services.operator,
    url: services.operator,
    outFields: ["*"],
    geometryType: "polyline",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpOperator,
    renderer: renderInfo.analyticsOutline,
    maxScale: 0,
    // definitionExpression:"datetime = date'04/05/2022, 12:00'",
    // definitionExpression: "date >= date'"+ today.toLocaleDateString()+"' and date <= date'"+nextday.toLocaleDateString()+"'",
    visible: false
  })

  export const operatorData = new FeatureLayer({
    // source: services.operator,
    effect:bloomStyle,
    url: services.operatorData,
    outFields: ["*"],
    geometryType: "polyline",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpOperator,
    renderer: renderInfo.operatorRenderer,
    maxScale: 0,
    // definitionExpression:"datetime = date'04/05/2022, 12:00'",
    // definitionExpression: "date >= date'"+ today.toLocaleDateString()+"' and date <= date'"+nextday.toLocaleDateString()+"'",
    visible: false
  })

  export const operatorBikeData = new FeatureLayer({
    // source: services.operator,
    effect:bloomStyle,
    url: services.operatorBikeData,
    outFields: ["*"],
    geometryType: "polyline",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpOperator,
    renderer: renderInfo.operatorRenderer,
    maxScale: 0,
    // definitionExpression:"datetime = date'04/05/2022, 12:00'",
    // definitionExpression: "date >= date'"+ today.toLocaleDateString()+"' and date <= date'"+nextday.toLocaleDateString()+"'",
    visible: false
  })


  export const operatorPedestrianData = new FeatureLayer({
    // source: services.operator,
    effect:bloomStyle,
    url: services.operatorPedestrianData,
    outFields: ["*"],
    geometryType: "polyline",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpOperator,
    renderer: renderInfo.operatorRenderer,
    maxScale: 0,
    // definitionExpression:"datetime = date'04/05/2022, 12:00'",
    // definitionExpression: "date >= date'"+ today.toLocaleDateString()+"' and date <= date'"+nextday.toLocaleDateString()+"'",
    visible: false
  })

  export const operatorBusData = new FeatureLayer({
    // source: services.operator,
    effect:bloomStyle,
    url: services.operatorBusData,
    outFields: ["*"],
    geometryType: "polyline",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpOperator,
    renderer: renderInfo.operatorRenderer,
    maxScale: 0,
    // definitionExpression:"datetime = date'04/05/2022, 12:00'",
    // definitionExpression: "date >= date'"+ today.toLocaleDateString()+"' and date <= date'"+nextday.toLocaleDateString()+"'",
    visible: false
  })

  export const operatorAvailableDates = new FeatureLayer({
    // source: services.operator,
    effect:bloomStyle,
    url: services.operatorAvailableDates,
    outFields: ["*"],
    geometryType: "polyline",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpOperator,
    renderer: renderInfo.operatorRenderer,
    maxScale: 0,
    // definitionExpression:"datetime = date'04/05/2022, 12:00'",
    // definitionExpression: "date >= date'"+ today.toLocaleDateString()+"' and date <= date'"+nextday.toLocaleDateString()+"'",
    visible: false
  })

  export const op_car_day = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_car_day_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: false
  })

  export const op_car_week = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_car_week_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: false
  })

  export const op_car_month = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_car_month_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: false
  })

  export const op_car_year = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_car_year_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: false
  })

  export const op_bike_day = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_bike_day_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_bike_week = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_bike_week_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_bike_month = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_bike_month_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_bike_year = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_bike_year_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_pedestrian_day = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_pedest_day_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_pedestrian_week = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_pedest_week_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_pedestrian_month = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_pedest_month_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_pedestrian_year = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_pedest_year_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_bus_day = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_bus_day_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_bus_week = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_bus_week_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_bus_month = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_bus_month_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const op_bus_year = new FeatureLayer({
    effect:bloomStyle,
    url: services.op_bus_year_url,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.trailsRendererOperatorDynamic(),
    maxScale: 0,
    visible: true
  })

  export const extent = new FeatureLayer({
    effect:bloomStyle,
    url: services.extent,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.extentOutline,
    maxScale: 0,
    visible: false
  })

  export const hexGrid = new FeatureLayer({
    //effect:bloomStyle,
    url: services.hexGrid,
    outFields: ["*"],
    geometryType: "polyline",
    renderer: renderInfo.hexGridRender,
    maxScale: 0,
    visible: false
  })

  export const nightLayer = new FeatureLayer({
    effect:bloomStyle,
    url: services.nightTable,
    outFields: ["*"],
    maxScale: 0,
    visible: false
  })

  export const dayLayer = new FeatureLayer({
    effect:bloomStyle,
    url: services.dayTable,
    outFields: ["*"],
    maxScale: 0,
    visible: false
  })

  export const infoLayer = new FeatureLayer({
    effect:bloomStyle,
    url: services.infoTable,
    outFields: ["*"],
    maxScale: 0,
    visible: false
  })

  export const meteoLayer = new FeatureLayer({
    effect:bloomStyle,
    url: services.meteo,
    outFields: ["*"],
    maxScale: 0,
    visible: false
  })

  export const juduLayer = new FeatureLayer({
    url: services.juduData,
    outFields: ["*"],
    maxScale: 0,
    visible: false
  })
 
  export const heatmapArr = [
    [
      [strava_bike_day, strava_bike_week, strava_bike_month, strava_bike_year], 
      [strava_pedestrian_day, strava_pedestrian_week, strava_pedestrian_month, strava_pedestrian_year],
      []
    ], 
    [
      
      [op_bike_day, op_bike_week, op_bike_month, op_bike_year],
      [op_pedestrian_day, op_pedestrian_week, op_pedestrian_month, op_pedestrian_year],
      [op_car_day, op_car_week, op_car_month, op_car_year],
      [op_bus_day, op_bus_week, op_bus_month, op_bus_year]
      
    ],
    [
      [operator, operatorBikeData, operatorOuline],
      [operator, operatorPedestrianData, operatorOuline],
      [operator, operatorData, operatorOuline],
      [operator, operatorBusData, operatorOuline]
    ],
    [
      [],
      [],
      [juduLayer, juduLayer, juduLayer, juduLayer]
    ]
  ]
  export const serviceArr = [strava_bike_day,strava_pedestrian_day,strava_month_bikes_old,strava_pedestrian_month,waze_jams,operator, operatorData, op_car_day, op_car_month, op_car_week, op_car_year, operatorAvailableDates]
