import React, { useEffect, useRef, useContext, useState, forwardRef } from "react";
import "../widgets/widgetcss/styles.css";
import "../../../../Judumas.css"
import DatePicker, {registerLocale} from "react-datepicker";
import { operatorAvailableDates } from "../../../../Services/serviceLayers";
import esriConfig from "@arcgis/core/config";
import lt from "date-fns/locale/lt"
import { MapContext } from "../../../../MapContext";
import uniqueValues from "@arcgis/core/smartMapping/statistics/uniqueValues";
import { toISOLocal } from "../../../../helpers/helper";



registerLocale("lt", lt); 

export default function Calendar ({token, monthWeekDay, dispatcher, headerText, colorScheme, inlineBool, existingStartDate = null , existingEndDate = null , dataSource=null, canSelectRange = true}) {
    const mapContext = useContext(MapContext);

    const [availableDates, setAvailableDates] = useState([]);
    const [datePickerState, setDatePickerState] = useState(0);
    const [initialRender, setInitialRender] = useState(0);
    const [dateRange, setDateRange] = useState([existingStartDate, existingEndDate]);
    const [startDate, endDate] = dateRange;

    const setCalndarBtn = (option) => {
        let availCpy = [...availableDates]
       //console.log(availCpy)
        let firstDate = new Date(availCpy[availCpy.length-1])
        //console.log(firstDate)
        switch(option) {
        case 'Day':
          firstDate.setDate(firstDate.getDate());
          break;
        case 'Week':
          firstDate.setDate(firstDate.getDate() - 7);
          break;
        case 'Month':
          firstDate.setMonth(firstDate.getMonth() - 1);
          break;
        default:
        }
        setDateRange([firstDate , availCpy[availCpy.length-1]])
      }

      const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className={"DatePickerInput " + colorScheme} onClick={onClick} ref={ref}>
          {value != "" ? value  : "Kalendorius"}
        </button>
      ));
    

      function waitForElm(selector) {
        return new Promise((resolve) => {
          if (document.querySelector(selector)) {
            return resolve(document.querySelectorAll(selector));
          }
    
          const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
              resolve(document.querySelectorAll(selector));
              observer.disconnect();
            }
          });
    
          observer.observe(document.body, {
            childList: true,
            subtree: true,
          });
        });
      }

      useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null){

            let day = new Date(dateRange[0]);
            day.setDate(day.getDate() - 1);
            let dateRangeLocal =
              "datetime >= timestamp'" +
              day.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) +
              " 23:59:59' and datetime < timestamp'" +
              dateRange[1].toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) +
              " 23:59:59'";
            if  (dispatcher === 0) {
              mapContext.dispatch({
                dateRange: dateRangeLocal,
              });
            } else {
              mapContext.dispatch({
                compareDate: dateRangeLocal,
              });
            } 
        }
      }, [dateRange]);

      useEffect(() => {
        console.log("intiail render " + initialRender)
      },[initialRender])

      useEffect(() => {
        let resetDates = [startDate, endDate]
        console.log(resetDates)
        mapContext.dispatch({
          calendarResetDates: [...resetDates]
        });
        console.log(mapContext.state.calendarResetDates)
        if (dispatcher === 0 && mapContext.state.dateRange === null){
          setDateRange([null,null])
          console.log("2")

        } else if (dispatcher !== 0 && mapContext.state.compareDate === null) {
          setDateRange([null,null])
          console.log("3")

        }
        console.log(dateRange)
        console.log(startDate)
        console.log(endDate)
      },[mapContext.state.dateRange, mapContext.state.compareDate])

      useEffect(() => {
        if (initialRender === 0) {
          if (dataSource !== null) {
            setAvailableDates(dataSource)
            return;
          }
          if (mapContext.state.dateWithData != null) {
            setAvailableDates(mapContext.state.dateWithData);
            return;
          }  
    
          esriConfig.request.interceptors.push({
            // set the `urls` property to the URL of the FeatureLayer so that this
            // interceptor only applies to requests made to the FeatureLayer URL
            urls: [mapContext.state.dataurl],
    
            error: function (error) {
              //console.log(error)
            },
    
            before: async function (params) {
              params.requestOptions.query.token = token;
              params.requestOptions.query = params.requestOptions.query || {};
            },
          });
          let uniqueDates = [];
          //console.log("UniqueValues")
          uniqueValues({
            layer: operatorAvailableDates,
            field: "date_str",
          }).then(function (response) {
            // prints each unique value and the count of features containing that value
            let infos = response.uniqueValueInfos;
            let availableDatesLocal = [];
            let parsedDates = [];
            infos.forEach(function (info) {
              //console.log((info.value))
              //console.log( Math.floor(info.value/1000/60/60/24))
              //info.value = Math.floor((info.value) / 1000 / 60 / 60 / 24) * 24 * 60 * 60 * 1000;
              //info.value = info.value
              //console.log("date:" + info.value);
              //let addDate = new Date(info.value)
    
              if (!availableDatesLocal.includes(info.value) && info.value != null) {
                //let addDate = new Date(info.value)
                //console.log(date)
                //console.log(info.value)
                let splitDate = info.value.split('/')
                let addDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
                parsedDates.push(addDate);
                availableDatesLocal.push(info.value);
                //setAvailableDates(availableDatesLocal)
                //console.log(availableDatesLocal)
              }
            });
            
            /*availableDatesLocal.forEach((date) => {
              let addDate = new Date(date);
              
            });*/
            //console.log(parsedDates)
            setAvailableDates(parsedDates);
            mapContext.dispatch({
              dateWithData: parsedDates,
            });
            //console.log(availableDates)
          });
          setInitialRender(1);
        }
      });

    return (

        <>
        <div className="">
            {headerText}
            
            {/*<h1>{data[2] !== null ? data[0] : ""}</h1>*/}
        </div>
        
        { availableDates.length === 0 ? <div class="loader"></div> :
                monthWeekDay === true ? 
                <>
                <button className=' ClearBtn SidebarBtn'onClick={() => {setCalndarBtn("Month")}}>Mėnuo</button>
                <button className=' ClearBtn SidebarBtn'onClick={() => {setCalndarBtn("Week")}}>Savaitė</button>
                <button className=' ClearBtn SidebarBtn' onClick={() => {setCalndarBtn("Day")}}>Diena</button> 
                
                </>
                :
                <></>}
        {/*<button className=' ready1' onClick={()=> {queryDate()}}>filter</button>*/}
        {inlineBool === false ?   <><DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            customInput={<ExampleCustomInput/>}
            calendarClassName={"DatePickerWrapper " + colorScheme}
            selectsDisabledDaysInRange={true}
            inline = {inlineBool}
            includeDates={availableDates}
            placeholderText="YYYY-MM-DD"
            locale={"lt"}
            disabledKeyboardNavigation
            onChange={(update) => {
            setDateRange(update);
            waitForElm(".SidebarBtn").then((button) => {
                    button.forEach((nav) => nav.classList.remove("Active"));
                    });
                  }}
            /></> :
         (datePickerState === 1) ? <><DatePicker
            selectsRange={canSelectRange}
            startDate={startDate}
            endDate={endDate}
            customInput={<ExampleCustomInput/>}
            calendarClassName={"DatePickerWrapper " + colorScheme}
            selectsDisabledDaysInRange={true}
            inline = {inlineBool}
            includeDates={availableDates}
            placeholderText="YYYY-MM-DD"
            locale={"lt"}
            disabledKeyboardNavigation
            onChange={(update) => {
              if (canSelectRange === false) {
                setDateRange([update, update]);
              } else {
                setDateRange(update);
              }
            
            waitForElm(".SidebarBtn").then((button) => {
                    button.forEach((nav) => nav.classList.remove("Active"));
            });
            }}
        />
        <button className={' ClearBtn DatePickerClose ' + colorScheme} onClick={() => {setDatePickerState(0)}}>^</button>
        </> : <button className={' ClearBtn MarginBtn DatePickerOpen ' + colorScheme} onClick={() => {setDatePickerState(1)}}>{  (dateRange[0] == null || dateRange[1] == null) ? "Kalendorius" : toISOLocal(startDate).split('T')[0].replaceAll('-', '/')  + " - "+toISOLocal(endDate).split('T')[0].replaceAll('-', '/')}</button> }
        </>
    )
}
