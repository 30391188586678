import react from "react"
import { clientID, redirectURLLocal, redirectURLProd, redirectURLDev } from "../config";
import { MapContext } from "../MapContext";
import { useContext } from "react";
import Appv2 from "../Appv2";
import {useEffect} from "react";


const Auth = () => {
  const mapContext = useContext(MapContext);
  let url = window.location;
  console.log(url.hash)

  useEffect(() => {
    document.title = "Judumo Portalas";
  }, [])

  return (
    (url.hash  === "")
    ?
     <a href={`http://opencity.vplanas.lt/portal/sharing/oauth2/authorize?client_id=${clientID}&response_type=token&expiration=20160&redirect_uri=${redirectURLProd}`}>Sign In With ArcGIS</a>
    :
    <Appv2/> 
  );
};

export default Auth